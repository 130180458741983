import { useStyles, Flex, Caption } from '@archipro-design/aria';
import * as S from './RightSideNewBoarderDrawer.style';
import { CloseLine } from '@archipro-design/icons';
import { Button as RoccoButton } from '@archipro/rocco/components/button';

interface DesignBoardDrawerHeaderProps {
    onClose: () => void;
    headerTitle?: string;
}

export const DesignBoardDrawerHeader = ({
    onClose,
    headerTitle = 'Create a board',
}: DesignBoardDrawerHeaderProps) => {
    const { css } = useStyles();

    const onClickCloseButton = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
    };

    return (
        <div>
            <Flex hAlign="stretch" vAlign="center" space="between">
                <Caption
                    className={css(S.Header)}
                    variant="01"
                    weight={'medium'}
                >
                    {headerTitle}
                </Caption>
                <RoccoButton
                    color="white"
                    size="default"
                    onClick={onClickCloseButton}
                    shape="default"
                    weight="normal"
                    className="px-2"
                >
                    <CloseLine />
                </RoccoButton>
            </Flex>
        </div>
    );
};
